<template>
  <div
    v-click-outside="hide"
    class="selector">
    <div
      class="flex brdr_r_8 selector_body"
      @click="openDropdown">
      <div class="selector_body_text">
        <label class="selected_label" ref="label1">{{title}}</label>
        <div class="medium_m selector_body_text_item">
          <p :title="selectedOption">{{selectedOption}}</p>
        </div>
      </div>
      <span
        class="selector_icon"
        :class="[isDropdownOpen ? 'rotate' : '']"
        v-html="ARROW_DOWN_SVG"></span>
    </div>
    <div class="flex options bckg_white brdr_r_8" v-if="isDropdownOpen">
      <div class="options_div brdr_r_8">
        <input
          v-model="search"
          type="text"
          class="medium_s"
          :placeholder="$t('search')">
        <ul class="medium_s" v-if="optionss.length === 0">
          <li>
            {{$t('no_matches')}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'role'">
          <li
            @click="selectOption(item)"
            v-for="(item, index) in optionss"
            :key="item + index">
            {{item.name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'group'">
          <li
            @click="selectOption(item)"
            v-for="(item, index) in optionss"
            :key="item + index">
            {{item.title}}
          </li>
        </ul>
        <ul class="medium_s" v-if="this.type === 'category' || this.type === 'tagged'">
          <li
            @click="selectOption(item)"
            v-for="(item, index) in optionss"
            :key="item + index">
            {{item.tag_name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'aCategory'">
          <li @click="resetSelector('category', '_prgrmCtgrSv')">
            {{$t('all')}}
          </li>
          <li
            @click="selectOption(item, 'category', '_prgrmCtgrSv')"
            v-for="(item, index) in optionss"
            :key="item + index">
            {{item.tag_name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'week'">
          <li
            @click="selectOption(index)"
            v-for="(item, index) in optionss"
            :key="item + index">
            {{item}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'aWeek'">
          <li
            @click="selectOption(index)"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item">
            {{item}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'tag' && optionss.length">
          <li @click="resetSelector('category', Number(selectedSection) === 6 ? '_crsCtlgCtgrSv' : '_crsGstCtlgDgrSv')">
            {{$t('all')}}
          </li>
          <li
            @click="selectOption(item, 'category', Number(selectedSection) === 6 ? '_crsCtlgCtgrSv' : '_crsGstCtlgDgrSv')"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item">
            {{item.tag_name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'degree' && optionss.length">
          <li @click="resetSelector('degree', Number(selectedSection) === 2 ? '_prgrmDgrSv' : Number(selectedSection) === 6 ? '_crsCtlgDgrSv' : '_crsGstCtlgDgrSv')">
            {{$t('all')}}
          </li>
          <li
            @click="selectOption(item, 'degree', Number(selectedSection) === 2 ? '_prgrmDgrSv' : Number(selectedSection) === 6 ? '_crsCtlgDgrSv' : '_crsGstCtlgDgrSv')"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item">
            {{item.degree_name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'eduDegree' || type === 'year'">
          <li
            @click="selectOption(item)"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item">
            {{item}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'format' && optionss.length">
          <li @click="resetSelector('format', Number(selectedSection) === 2 ? '_prgrmFrmtSv' : Number(selectedSection) === 6 ? '_crsCtlgFrmtSv' : '_crsGstCtlgFrmtSv')">
            {{$t('all')}}
          </li>
          <li
            @click="selectOption(item, 'format', Number(selectedSection) === 2 ? '_prgrmFrmtSv' : Number(selectedSection) === 6 ? '_crsCtlgFrmtSv' : '_crsGstCtlgFrmtSv')"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item">
            {{item.format_name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'teacher'">
          <li
            @click="selectOption(item)"
            v-for="item in optionss"
            :key="item.id"
            :title="`${item.first_name} ${item.last_name}`">
            {{item.first_name}} {{item.last_name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'g_teacher'">
          <li
            @click="selectOption(item)"
            v-for="item in optionss"
            :key="item.id"
            :title="`${item.first_name} ${item.last_name}`">
            {{item.first_name}} {{item.last_name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'course'">
          <li
            @click="selectOption(item)"
            v-for="item in optionss"
            :key="item.id"
            :title="`${item.title_ru}`">
            {{item.title_ru}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'aCourse'">
          <li
            @click="selectOption(item)"
            v-for="item in optionss"
            :key="item.course_id.id"
            :title="`${item.course_id.title_ru}`">
            {{item.course_id.title_ru}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'sections'">
          <li
            @click="selectOption(item)"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item.title_ru">
            {{index + 1}}) {{item.title_ru}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'type' || this.type === 'format_lesson' || this.type === 'type_lesson'">
          <li
            @click="selectOption(item)"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item.name">
            {{item.name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'language' && optionss.length">
          <li @click="resetSelector('language', Number(selectedSection) === 2 ? '_prgrmLngSv' : Number(selectedSection) === 6 ? '_crsCtlgLnggSv' : '_crsGstCtlgLngSv')">
            {{$t('all')}}
          </li>
          <li
            @click="selectOption(item, 'language', Number(selectedSection) === 2 ? '_prgrmLngSv' : Number(selectedSection) === 6 ? '_crsCtlgLnggSv' : '_crsGstCtlgLngSv')"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item.name">
            {{item.name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'currency'">
          <li
            @click="selectOption(item)"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item.name">
            {{item.code}} {{item.name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'currencyCode'">
          <li
            @click="selectOption(item)"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item.code">
            {{item.code}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'cancelTime' || type === 'transferTime'">
          <li
            @click="selectOption(item)"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item.time">
            {{item.time}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'lessons'">
          <li
            @click="selectOption(item)"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item.title_ru">
            {{item.title_ru}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'city' && optionss.length">
          <li @click="resetSelector('city', Number(selectedSection) === 2 ? '_prgrmCtSv' : Number(selectedSection) === 6 ? '_crsCtlgCtSv' : '_crsGstCtlgCtSv')">
            {{$t('all')}}
          </li>
          <li
            @click="selectOption(item, 'city', Number(selectedSection) === 2 ? '_prgrmCtSv' : Number(selectedSection) === 6 ? '_crsCtlgCtSv' : '_crsGstCtlgCtSv')"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item.city">
            {{item.city}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'exerciseList'">
          <li
            @click="selectOption(item)"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item.title_ru">
            {{item.title_ru}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'sort' || type === 'countries' || type === 'task_check'">
          <li
            @click="selectOption(item)"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item">
            {{item}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'speciality' || type === 'univer' || type === 'package'">
          <li
            v-for="(item, index) in optionss"
            :key="index"
            @click="selectOption(item)"
            :title="item">
            {{item.name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'university' && optionss.length">
          <li @click="resetSelector('univer', Number(selectedSection) === 2 ? '_prgrmUnvrSv' : Number(selectedSection) === 6 ? '_crsCtlgUnvSv' : '_crsGstCtlgUnvrSv')">
            {{$t('all')}}
          </li>
          <li
            @click="selectOption(item, 'univer', Number(selectedSection) === 2 ? '_prgrmUnvrSv' : Number(selectedSection) === 6 ? '_crsCtlgUnvSv' : '_crsGstCtlgUnvrSv')"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item">
            {{item.name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'faculty' && optionss.length">
          <li @click="resetSelector('faculty', Number(selectedSection) === 2 ? '_prgrmFcltSv' : Number(selectedSection) === 6 ? '_crsCtlgFcltySv' : '_crsGstCtlgFcltSv')">
            {{$t('all')}}
          </li>
          <li
            @click="selectOption(item, 'faculty', Number(selectedSection) === 2 ? '_prgrmFcltSv' : Number(selectedSection) === 6 ? '_crsCtlgFcltySv' : '_crsGstCtlgFcltSv')"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item">
            {{item.name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'certificate'">
          <li
            @click="selectOption(index, 'certificate')"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item">
            {{item}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'known_lang'">
          <li
            @click="selectOption(item, 'known_lang')"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item">
            {{item.name}}
          </li>
        </ul>
        <ul class="medium_s" v-if="type === 'univer_tag' && optionss.length">
          <li @click="resetSelector('univer_tag', Number(selectedSection) === 2 ? '_prgrmUnvrTgSv' : Number(selectedSection) === 6 ? '_crsCtlgUnvTgSv' : '_crsGstCtlgUnvrTgSv')">
            {{$t('all')}}
          </li>
          <li
            @click="selectOption(item, 'univer_tag', Number(selectedSection) === 2 ? '_prgrmUnvrTgSv' : Number(selectedSection) === 6 ? '_crsCtlgUnvTgSv' : '_crsGstCtlgUnvrTgSv')"
            v-for="(item, index) in optionss"
            :key="index"
            :title="item">
            {{item.name}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ARROW_DOWN_SVG } from '@/store/icons'

export default {
  name: 'SelectedComponent',
  props: {
    title: String,
    type: String,
    options: {},
    selected: [Number, String, Object],
    wideSelect: Boolean,
    percentWidth: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ARROW_DOWN_SVG,
      isDropdownOpen: false,
      search: '',
      selectedSection: localStorage.getItem('nb')
    }
  },
  computed: {
    optionss () {
      const optionss = this.options.filter((post) => {
        if (this.type === 'univer_tag' || this.type === 'university' || this.type === 'faculty' || this.type === 'univer' || this.type === 'speciality' || this.type === 'role' || this.type === 'type' || this.type === 'currency' || this.type === 'format_lesson' || this.type === 'package' || this.type === 'type_lesson' || this.type === 'language') {
          return post.name.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'aCategory' || this.type === 'category' || this.type === 'tagged' || this.type === 'tag') {
          return post.tag_name.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'aWeek' || this.type === 'week') {
          return post.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'teacher' || this.type === 'g_teacher') {
          return post.first_name.toLowerCase().includes(this.search.toLowerCase()) || post.last_name.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'group') {
          return post.title.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'course' || this.type === 'sections' || this.type === 'exerciseList') {
          return post.title_ru.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'cancelTime' || this.type === 'transferTime') {
          return post.time.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'degree') {
          return post.degree_name.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'format') {
          return post.format_name.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'aCourse') {
          return post.course_id.title_ru.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'lessons') {
          return post.title_ru.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'city') {
          return post.city.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'sort' || this.type === 'countries' || this.type === 'task_check') {
          return post.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'currencyCode') {
          return post.code.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'certificate' || this.type === 'eduDegree') {
          return post.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'known_lang') {
          return post.name.toLowerCase().includes(this.search.toLowerCase())
        } else if (this.type === 'year') {
          return post.includes(this.search)
        }
        return post
      })
      return optionss
    },
    selectedOption: function () {
      let option = ''
      if (this.options?.length) {
        if (this.type === 'week') {
          this.options.map((elem, index) => {
            if (index + 1 === Number(this.selected)) {
              option = elem
            }
            return elem
          })
        } else if (this.type === 'role') {
          this.options.map((elem) => {
            if (elem.option === this.selected) {
              option = elem.name
            }
            return elem
          })
        } else if (this.type === 'group') {
          this.options.map((elem) => {
            if (elem.index === this.selected) {
              option = elem.title
            }
            return elem
          })
        } else if (this.type === 'teacher') {
          this.options.map((elem) => {
            if (elem.id === this.selected) {
              option = `${elem.first_name} ${elem.last_name}`
            } else if (this.selected === '') {
              const label = this.$refs.label1
              label?.classList.add('not_selected_label')
              label?.classList.remove('selected_label')
            }
            return elem
          })
        } else if (this.type === 'course') {
          this.options.map((elem) => {
            if (elem.id === this.selected) {
              option = elem.title_ru
            }
            return elem
          })
        } else if (this.type === 'aCourse') {
          this.options.map((elem) => {
            if (elem.course_id.id === this.selected) {
              option = elem.course_id.title_ru
            }
            return elem
          })
        } else if (this.type === 'g_teacher') {
          this.options.map((elem) => {
            if (elem.id === this.selected) {
              option = `${elem.first_name} ${elem.last_name}`
            }
            return elem
          })
        } else if (this.type === 'aCategory' || (this.type === 'category' && this.options.length)) {
          this.options.map((elem) => {
            if (elem.id === this.selected) {
              option = elem.tag_name
            }
            return elem
          })
        } else if (this.type === 'tagged') {
          this.options.map((elem) => {
            if (elem.id === this.selected.id) {
              option = elem.tag_name
            }
            return elem
          })
        } else if (this.type === 'aWeek') {
          this.options.map((elem) => {
            if (elem === this.selected) {
              option = elem
            }
            return elem
          })
        } else if (this.type === 'tag' && this.options.length) {
          this.options.map((elem) => {
            if (elem.id === this.selected) {
              option = elem.tag_name
            } else if (this.selected === '') {
              const label = this.$refs.label1
              label?.classList.add('not_selected_label')
              label?.classList.remove('selected_label')
            }
            return elem
          })
        } else if (this.type === 'sections') {
          this.options.map((elem, index) => {
            if (elem.id === this.selected) {
              option = index + 1 + ') ' + elem.title_ru
            }
            return elem
          })
        } else if (this.type === 'degree' && this.options.length) {
          this.options.map((elem) => {
            if (elem.id === this.selected) {
              option = elem.degree_name
            }
            return elem
          })
        } else if (this.type === 'format' && this.options.length) {
          this.options.map((elem) => {
            if (elem.id === this.selected) {
              option = elem.format_name
            }
            return elem
          })
        } else if (this.type === 'city' && this.options.length) {
          this.options.map((elem) => {
            if (elem.id === this.selected) {
              option = elem.city
            }
            return elem
          })
        } else if (this.type === 'type' || this.type === 'format_lesson' || this.type === 'type_lesson' || this.type === 'language') {
          this.options.map((elem) => {
            if (elem.index === this.selected) {
              option = elem.name
            }
            return elem
          })
        } else if (this.type === 'currency') {
          this.options.map((elem) => {
            if (elem.id === this.selected) {
              option = `${elem.code} ${elem.name}`
            }
            return elem
          })
        } else if (this.type === 'currencyCode') {
          this.options.map((elem) => {
            if (elem.id === this.selected.id) {
              option = elem.code
            }
            return elem
          })
        } else if (this.type === 'cancelTime' || this.type === 'transferTime') {
          this.options.map((elem) => {
            if (elem.index === this.selected) {
              option = elem.time
            }
            return elem
          })
        } else if (this.type === 'lessons' || this.type === 'exerciseList') {
          this.options.map((elem) => {
            if (elem.id === this.selected.id) {
              option = elem.title_ru
            }
            return elem
          })
        } else if (this.type === 'sort' || this.type === 'countries' || this.type === 'task_check' || this.type === 'eduDegree' || this.type === 'year') {
          this.options.map((elem) => {
            if (elem === this.selected) {
              option = elem
            }
            return elem
          })
        } else if ((this.type === 'package' || this.type === 'univer_tag' || this.type === 'faculty' || this.type === 'university') && this.options.length) {
          this.options.map((elem) => {
            console.log(this.selected)
            if (elem.id === this.selected) {
              option = elem.name
            }
            return elem
          })
        } else if (this.type === 'certificate') {
          this.options.map((elem, index) => {
            if (index === this.selected) {
              option = elem
            }
            return elem
          })
        } else if (this.type === 'known_lang') {
          this.options.map((elem) => {
            if (elem.name === this.selected) {
              option = elem.name
            }
            return elem
          })
        }
      }
      return option
    }
  },
  watch: {
    selectedOption: function () {
      this.unfocused()
    }
  },
  methods: {
    openDropdown: function () {
      this.isDropdownOpen = !this.isDropdownOpen
      if (this.isDropdownOpen === false) {
        this.unfocused()
      }
    },
    hide: function () {
      this.isDropdownOpen = false
      this.unfocused()
    },
    resetSelector: function (e, name) {
      this.search = ''
      this.isDropdownOpen = false
      const data = [e, name]
      this.$emit('reset-select', data)
    },
    selectOption: function (value, type, name) {
      this.isDropdownOpen = false
      this.search = ''
      if (this.type === 'role') {
        this.$emit('select-item', value.option)
        this.focused()
      } else if (this.type === 'package' || this.type === 'univer_tag' || this.type === 'tagged' || this.type === 'university' || this.type === 'faculty' || this.type === 'task_check' || this.type === 'countries' || this.type === 'exerciseList' || this.type === 'lessons' || this.type === 'aCourse' || this.type === 'g_teacher' || this.type === 'transferTime' || this.type === 'cancelTime' || this.type === 'currencyCode' || this.type === 'currency' || this.type === 'type' || this.type === 'sections' || this.type === 'language' || this.type === 'tag' || this.type === 'week' || this.type === 'aCategory' || this.type === 'teacher' || this.type === 'group' || this.type === 'course' || this.type === 'aWeek') {
        if (this.type === 'univer_tag' || this.type === 'tag' || this.type === 'university' || this.type === 'faculty' || this.type === 'aCategory' || this.type === 'language') {
          const data = [value, type, name]
          this.$emit('select-item', data)
        } else {
          this.$emit('select-item', value)
        }
        this.focused()
      } else if (this.type === 'univer' || this.type === 'speciality' || this.type === 'category' || this.type === 'degree' || this.type === 'format' || this.type === 'city') {
        if (this.type === 'degree' || this.type === 'city' || this.type === 'format') {
          const data = [value, type, name]
          this.$emit('select-item', data)
        } else {
          this.$emit('select-item', value)
        }
        this.unfocused()
      } else if (this.type === 'format_lesson' || this.type === 'type_lesson') {
        this.$emit('select-item', value.index)
        this.focused()
      } else if (this.type === 'sort' || this.type === 'known_lang' || this.type === 'certificate' || this.type === 'eduDegree' || this.type === 'year') {
        this.$emit('select-item', value)
        this.focused()
      }
    },
    focused: function () {
      const label = this.$refs.label1
      label.classList.add('selected_label')
      label.classList.remove('not_selected_label')
    },
    unfocused: function () {
      if (this.selectedOption === '') {
        const label = this.$refs.label1
        label.classList.add('not_selected_label')
        label.classList.remove('selected_label')
      } else {
        this.focused()
      }
    }
  },
  mounted () {
    if (this.type === 'package' || this.type === 'sort' || this.type === 'tagged' || this.type === 'univer_tag' || this.type === 'known_lang' || this.type === 'year' || this.type === 'eduDegree' || this.type === 'certificate' || this.type === 'tag' || this.type === 'task_check' || this.type === 'university' || this.type === 'faculty' || this.type === 'speciality' || this.type === 'univer' || this.type === 'currencyCode' || this.type === 'countries' || this.type === 'exerciseList' || this.type === 'city' || this.type === 'lessons' || this.type === 'aCourse' || this.type === 'format' || this.type === 'degree' || this.type === 'g_teacher' || this.type === 'format_lesson' || this.type === 'type_lesson' || this.type === 'category' || this.type === 'group' || this.type === 'aWeek' || this.type === 'role' || this.type === 'aCategory' || this.type === 'language' || this.type === 'course' || this.type === 'type' || this.type === 'teacher' || this.type === 'student' || this.type === 'cuurency' || this.type === 'transferTime' || this.type === 'cancelTime') {
      this.unfocused()
    }
  }
}
</script>
