export const PLAY_SVG = `<svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4 18.7123V5.28778C4 4.27931 5.30373 3.66342 6.31143 4.19584L19.2859 11.0509C20.2487 11.5596 20.2354 12.7543 19.2615 13.2474L6.28701 19.8169C5.27864 20.3274 4 19.7099 4 18.7123Z" fill="#004DE7"/>
                        </svg>`

export const LIVE_SVG = `<svg class="fa" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="12" cy="12" r="4" fill="#004DE7"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#004DE7"/>
                        </svg>`

export const TEST_SVG = `<svg class="fa " width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9752 6.36025C20.6046 6.89881 20.6783 7.84569 20.1398 8.47517L12.2576 17.6881C11.3376 18.7634 9.70927 18.8614 8.66695 17.9042L3.9854 13.6048C3.37524 13.0445 3.33486 12.0956 3.89521 11.4854C4.45557 10.8753 5.40445 10.8349 6.01462 11.3952L10.3147 15.3443L17.8602 6.52488C18.3988 5.8954 19.3457 5.82169 19.9752 6.36025Z" fill="#004DE7"/>
                        </svg>`

export const SETTINGS_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7324 6H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H12.7324C12.3866 8.5978 11.7403 9 11 9C10.2597 9 9.61337 8.5978 9.26756 8H5C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6H9.26756C9.61337 5.4022 10.2597 5 11 5C11.7403 5 12.3866 5.4022 12.7324 6ZM5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H15.2676C15.6134 13.5978 16.2597 14 17 14C17.7403 14 18.3866 13.5978 18.7324 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H18.7324C18.3866 10.4022 17.7403 10 17 10C16.2597 10 15.6134 10.4022 15.2676 11H5ZM4 17C4 16.4477 4.44772 16 5 16H7.26756C7.61337 15.4022 8.25972 15 9 15C9.74028 15 10.3866 15.4022 10.7324 16H19C19.5523 16 20 16.4477 20 17C20 17.5523 19.5523 18 19 18H10.7324C10.3866 18.5978 9.74028 19 9 19C8.25972 19 7.61337 18.5978 7.26756 18H5C4.44772 18 4 17.5523 4 17Z" fill="#004DE7"/>
                            </svg>`

export const PLUS_SVG = `<svg style="cursor: pointer;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 3.30435C13.5 2.58398 12.8284 2 12 2C11.1716 2 10.5 2.58398 10.5 3.30435V10.5H3.30435C2.58398 10.5 2 11.1716 2 12C2 12.8284 2.58398 13.5 3.30435 13.5H10.5V20.6957C10.5 21.416 11.1716 22 12 22C12.8284 22 13.5 21.416 13.5 20.6957V13.5H20.6957C21.416 13.5 22 12.8284 22 12C22 11.1716 21.416 10.5 20.6957 10.5H13.5V3.30435Z" fill="#004DE7"/>
                        </svg>`

export const PLUS_SVG_20 = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 1.30435C11.5 0.583976 10.8284 0 10 0C9.17157 0 8.5 0.583976 8.5 1.30435V8.5H1.30435C0.583976 8.5 0 9.17157 0 10C0 10.8284 0.583977 11.5 1.30435 11.5H8.5V18.6957C8.5 19.416 9.17157 20 10 20C10.8284 20 11.5 19.416 11.5 18.6957V11.5H18.6957C19.416 11.5 20 10.8284 20 10C20 9.17157 19.416 8.5 18.6957 8.5H11.5V1.30435Z" fill="white"/>
                            </svg>`

export const ARROW_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16789 7.29289C8.55842 6.90237 9.19158 6.90237 9.58211 7.29289C9.97263 7.68342 9.97263 8.31658 9.58211 8.70711L7.28921 11H18.875C19.4273 11 19.875 11.4477 19.875 12C19.875 12.5523 19.4273 13 18.875 13H7.28921L9.58211 15.2929C9.97263 15.6834 9.97263 16.3166 9.58211 16.7071C9.19158 17.0976 8.55842 17.0976 8.16789 16.7071L4.38003 12.9192C3.87234 12.4116 3.87234 11.5884 4.38003 11.0808L8.16789 7.29289Z" fill="#004DE7"/>
                          </svg>`

export const LIST_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="4" cy="5" r="2" fill="#004DE7"/>
                          <circle cx="4" cy="12" r="2" fill="#004DE7"/>
                          <circle cx="4" cy="19" r="2" fill="#004DE7"/>
                          <rect x="9" y="4" width="13" height="2" rx="1" fill="#004DE7"/>
                          <rect x="9" y="11" width="13" height="2" rx="1" fill="#004DE7"/>
                          <rect x="9" y="18" width="13" height="2" rx="1" fill="#004DE7"/>
                        </svg>`

export const CARD_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="2" y="2" width="20" height="12" rx="2" fill="#004DE7"/>
                          <rect x="2" y="18" width="20" height="4" rx="2" fill="#004DE7"/>
                        </svg>`

export const SEARCH_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 14.5C12.2614 14.5 14.5 12.2614 14.5 9.5C14.5 6.73858 12.2614 4.5 9.5 4.5C6.73858 4.5 4.5 6.73858 4.5 9.5C4.5 12.2614 6.73858 14.5 9.5 14.5ZM9.5 17C13.6421 17 17 13.6421 17 9.5C17 5.35786 13.6421 2 9.5 2C5.35786 2 2 5.35786 2 9.5C2 13.6421 5.35786 17 9.5 17Z" fill="#949EB3"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3301 14.4074C13.8051 13.9022 14.618 13.8612 15.1458 14.316L21.5744 19.8544C22.1022 20.3091 22.145 21.0873 21.6699 21.5926C21.1949 22.0978 20.382 22.1388 19.8542 21.684L13.4256 16.1456C12.8978 15.6909 12.855 14.9127 13.3301 14.4074Z" fill="#949EB3"/>
                          </svg>`

export const ARROW_DOWN_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns= "http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4773 6.91336C21.1139 7.44341 21.2004 8.38922 20.6703 9.02588L14.7027 16.1937C13.3041 17.8737 10.7247 17.8747 9.32477 16.1957L3.33003 9.00604C2.79951 8.36977 2.88524 7.4239 3.52151 6.89338C4.15778 6.36286 5.10365 6.44859 5.63417 7.08486L11.6289 13.8937C11.8289 14.1336 12.1974 14.1335 12.3972 13.8935L18.3648 7.1064C18.8948 6.46974 19.8406 6.38331 20.4773 6.91336Z" fill="#004DE7"/>
                              </svg>`
